import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { setDelay, closeWebsocket,reInitializeWebsocket } from '../../initialize/websocket.js';
import { is_counttime } from '../../helpers';
import { Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';


var g_isPlaying = true

export default function DelayControl(props) {
  const { is_mobile, started_at, devices, setDevices } = props;
  const startTime = new Date(started_at || 0);
  const [dataRange, setDataRange] = useState({
    min: -4200,
    max: 0,
    step: 1,
  });
  const [timeOffset, setTimeOffset] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
				if(g_isPlaying)
					setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  //スライダーの最小値を更新
  useEffect(() => {
    const fiveMinutesBeforeStartTime = new Date(startTime.getTime() - 5 * 60 * 1000);
    const calculatedMin = Math.floor((fiveMinutesBeforeStartTime.getTime() - currentTime.getTime()) / 1000);
    const minTimeOffset = -4200; // 4200秒前（70分前）

    setDataRange(prevRange => ({
      ...prevRange,
      min: Math.max(calculatedMin, minTimeOffset), // -4200 か fiveMinutesBeforeStartTime のどちらか大きい方
    }));
  }, [currentTime]);


  // フォーマットする関数
  const formatTime = (date) => {
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  const formatTimeJST = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };


  // スライダーの値が変わったときの処理
  const handleSliderChange = (event, newValue) => {
    setTimeOffset(-newValue);  // 新しい値に基づいてtimeOffsetを更新
    setDelay(-newValue);

		//デバイス航跡をリセット
		if(!devices)
			return
		let deviceMap = new Map();
		devices.forEach(function(device, i) {
			device.resetMovementData();
			deviceMap.set( device.serial, device);
		});
		setDevices(deviceMap);
  };

  // 経過時間または残り時間の計算
  const isFuture = startTime > currentTime;
  const timeDifference = isFuture
    ? currentTime.getTime()
    : currentTime.getTime() - timeOffset * 1000;

  const displayTime = is_counttime()
    ? isFuture
      ? formatTime(new Date(startTime.getTime()-timeDifference))
      : formatTime(new Date(timeDifference -  startTime.getTime()))
    : formatTimeJST(new Date(timeDifference));

  const isAtRightEnd = -timeOffset === dataRange.max;
  const liveDotColor = isAtRightEnd ? 'red' : 'rgba(255, 0, 0, 0.5)';

  //Play or Stop
  const handlePlay = () => {
    setIsPlaying(true)
		g_isPlaying = true
		//現在時刻と、停止時の時刻の差分
		let delay = Math.floor(-1*(new Date() - currentTime.getTime() - timeOffset * 1000)/1000); 
		console.log("reInit:",delay)
		setTimeOffset(delay)
		reInitializeWebsocket(delay);
  }

  const handlePause = () => {
    setIsPlaying(false)
		g_isPlaying = false
		closeWebsocket();
  }

  return (
    <Grid container> 
      <Grid item xs={is_mobile ? 3 : 1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000' }} className={'nowtime'}>
        {displayTime}
        { !isPlaying?  (
          <Button onClick={handlePlay} style={{ color: '#ffffff' }}>
            <PlayArrowIcon />
          </Button> ) : (
          <Button onClick={handlePause} style={{ color: '#ffffff' }}>
            <PauseIcon />
          </Button>
          )}
      </Grid>
      <Grid item xs={is_mobile ? 7 : 10} style={{ backgroundColor: '#000000' }}>
        <Slider
          min={dataRange.min}
          max={dataRange.max}
          step={dataRange.step}
          value={-timeOffset}
          onChange={handleSliderChange}
          aria-labelledby="continuous-slider"
        />
      </Grid>
      <Grid item xs={is_mobile ? 2 : 1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000' }}>
        <span style={{ color: liveDotColor, marginRight: '4px' }}>●</span>
        <span style={{ color: '#ffffff' }}>LIVE</span>
      </Grid>     
    </Grid>
  );
}

